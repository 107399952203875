<template>
  <div>
    <p class="mt-4 mb-2 text-2xl font-semibold">Handouts</p>
    <Button sml newTab leading="flaticon-pdf-1" href="/RMR LSA FAQs.pdf">
      LSA FAQs
    </Button>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  }
};
</script>
